import React from 'react';
import pdf from '../../static/documents/MedicarePolicyIntake_210601.pdf'

export class Pdf5 extends React.Component {
  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.open(pdf)
    }
  }

  render() {
    return <></>
  }

}

export default Pdf5;
